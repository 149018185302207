@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  background:url("app/assets/images/bg.png") no-repeat scroll center;
  background-size: cover;
    font-family:"Poppins";
}
.img-fluid{
    max-width:100%;
    height:auto;
}
.flex{
    display:flex;
    align-items: center;
    justify-content: center;
}
.flex-end{
  display:flex;
  align-items: center;
  justify-content: flex-end;
}
.user{padding-right:50px;
border-bottom:1px solid #eaeaea;}
.user ul li:nth-child(n){
  content:'';
  margin-right:20px;
}
.user ul li:last-child(n){
  content:none;
  margin-right:0px;
}
.flex-between{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
ul{padding-left:0px;text-align:center;}
li{list-style-type: none;} 
.button_side button{display:block !important;background:none;border:none;
font-family: "Poppins";}
.multicryptowallet h1{text-align: center;
font-weight:700;text-transform: uppercase;font-size:60px}
.linear-gradient{
  background-image:linear-gradient(145deg,#D9E043, #80C886);
  border-radius:50px;
  border:1px solid #fff;
  font-family:"Poppins";
  padding:10px 20px;
  font-size:14px !important;
  font-weight:600
}
.linear-gradient:hover{
  background-image:linear-gradient(145deg, #80C886,#D9E043);
  border-radius:50px;
  border:1px solid #fff;
  font-family:"Poppins";
  padding:10px 20px;
  cursor:pointer;
  transition: all 0.5s ease-in-out;
}
.ios_mobile{margin-right:10px;}
.android_mobile{margin-right:10px;}
.banner{text-align:center;margin-top:30px;}
.menu_navbar{position: fixed;width: 262px;
  max-width: 100% !important;height: 100vh;background: #fff;
left:50px;}
.fullbody{min-height:100vh;}
.three_simple_steps{
  background:#f5f5f5;
  border-radius:30px;
  text-align: right;
  padding:20px;
  max-height:294px;
  min-height:294px;
}
.three_simple_steps img{text-align:right;
  margin-top: -75px;
  text-align: right;
  position: relative;
  right: -21px;
  width:100%
}
.three_simple_steps p{text-align: left;font-weight:700;color:#000}
.three_steps{width:81%;margin:auto}
.three_simple_steps:hover{
  background-image:linear-gradient(145deg,#D9E043, #80C886);
  transition:all 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: color 0.5s ease;
}
.three_simple_steps:hover p{
 color:#fff;
}
.clients_img{text-align:right}
.brands{
  background:#f5f5f5;
  padding:30px 0px;
}
.brands img{
  width:80%
}
.brands ul li:last-child{
  content:none;
  margin-right: 0px;
}
.brands ul li:nth-child(n){
  content:"";
  margin-right: 30px;
}
.funds{
  background:#f5f5f5;
  position:relative;
  min-height:250px;
  max-height:250px;
  border-radius: 30px;
  padding:30px;
}
.funds .image
{position: absolute !important;
  top: 135px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;}

.funds .images img{
  width:100px;height:100px;
}
.funds{margin-top:10px}
.footer-content li:last-child{
  content:none;
  margin-right:0px;
}
.footer-content li:nth-child(n){
  content:"";
  margin-right:20px;
  font-size:12px;
}
.main_content{margin-bottom:100px !important;}
.footer-store li{border:1px solid #000;border-radius: 30px;padding:5px 10px;}
.european .content{position: absolute;
  width: 200px;
  top: 62px;
  right: 0px;}
.european{position:relative;max-width:465px;}
.multicryptowallet .description{
  color: rgba(0,0,0,.4);
font-size: 14px;
margin: auto;
text-align: center;
width:56%;
font-weight: 500;
line-height: 21px;
}
/* .navabar{border-right:1px solid #ccc;} */
.multicryptoleft{padding-left:0px !important;}
.main_content{
  padding-left:0px !important;
}
.thirty_supported h2{text-align: center;font-weight:700;
  color: #373737;
  font-size: 40px;
  margin: auto;
  text-align: center;
  width: 65%;}
  .coin{margin-top:20px !important;}
  h1,h2{color:#373737}
.thirty_supported p{color: rgba(0,0,0,0.4);
  font-size: 14px;
  text-align: center;
  width: 63%;
  margin: auto;}
.chiptoss_plastic_card h2{font-weight:700}
.get_started h2{text-align: center;font-weight:700}
.get_started p{color:rgba(0,0,0,0.7);font-size:13px;text-align: center;
margin:auto}
.clients p{color:rgba(0,0,0,0.7);font-size:13px;text-align: left;}
.clients  h2{text-align: left;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;}
.footer p{
  color:rgba(0,0,0,0.7);font-size:12px;text-align: left;
  padding-right:50px
}
.easiest_fastest h2{
  text-align: left;font-weight:700;
  width:100%;
  font-size: 40px;
}
.easiest_fastest p{
  color:rgba(0,0,0,0.7);font-size:13px;text-align: left;
  padding-right:50px;
  width:100%;
}
.footer-store li:nth-child(n){
  content:'';
  margin-right:15px;
  font-size:13px;
}
.footer-store {margin-top: 20px;}
.footer-store li{cursor: pointer;}
.footer-store li:last-child{
  content:none;
  margin-right:0px;
}
.social-icons li:nth-child(n){
  content:'';
  margin-right:15px;
}
.social-icons li:last-child{
  content:none;
  margin-right:0px;
}
.easiest_fastest_way{text-align:center}
.european .content h1{color:#fff;font-size: 50px;
font-weight: 600;}
.european .content p{color:#fff;
  line-height: 43px;
font-size:22px}
.european .content button{font-size: 11px !important;
margin-top:15px}
.green_colored{background:#D4DF46;border-radius: 20px;
padding:50px;}
.green_colored h2{font-weight:700}
.card_issued h2{font-weight:700;color:#000}
.transaction h2{font-weight:700;color:#000}
.pink-btn{
  background:#F37B82;
  border-radius: 30px;
  padding:5px 20px;
  color:#fff;
  border:0px solid transparent;
  display:block;
  margin-top: 30px;
}
.violet-border {
  border-top:1px solid #9C6BBE
}
.easy_steps h2{padding-bottom:40px}
.marginTop{
  margin-top:-52px !important
}
.more{
  position:relative;text-align: center;
  max-width:1011px
}
.more .content{
  position: absolute;
top: 85px;
bottom: 0;
left: 30px;
padding: 20px;
right: 0;
}
.more .content h2{
  font-weight: 800;
  line-height: 54px;
  font-size: 40px;
  color: #000;
  text-align: left;
}
.more .content p{color:#000}
.more .content p{
  width:50%;
  text-align: left;
}
.accordion{
  width:90%;
  margin:auto
}
.p-relative{position: relative;}
.accordion-item{border:0px solid transparent !important}
.accordion-header button{font-weight:500}
.search{position:relative}
.search button{
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 8px 35px !important;
  width: 145px !important;
  height: 43px !important;}
.search input{border-radius: 30px;height:43px}
.documents_support .border{
  border:1px solid #000;
  padding:10px;
  border-radius:10px;
}
.pin{background: #fff;padding:10px 20px;border-radius: 20px;}
.need_to_know_more{position:relative;}
.need_to_know_more .content{position: absolute;
  top: 35px;
  padding: 20px;
  width: 93%;
  left: 0;
  right: 0;
  margin: auto;}
.how_to_guides{position:relative;width:93%;text-align: center;}
.how_to_guides .content{position: absolute;
  top: 35px;
  padding: 20px;
  left: 0;
  right: 0;
  margin: auto;}
.how_to_guides .content button{border-radius: 30px;border:1px solid #fff;
margin-top:20px}
.need_to_know_more .content button{border-radius: 30px;border:1px solid #fff;
margin-top:20px}
.button_side li{margin-bottom:20px;text-align: left;}
.button_side li button{font-size:12px;font-weight:700}
.multicryptowallet{padding-top:50px;}
.black{
  color:#000;
}
.white{
  color:#707070
}
.main_content{  width: 80%;
  margin: auto;}
  .faq h2{text-align: left;
    font-weight: 700;}
.button_side{margin-top:130px;}
.funds_transfer{margin-top:30px;}
.accordion-button:not(.collapsed) {
  color: #000;
  background: #fff !important;
}
.search .linear-gradient:hover{width:145px;height:42px}
.need_to_know_more{
  text-align:center;
}
.chiptoss_card h2{font-weight:700}
.chiptoss_card  p{color:rgba(0,0,0,0.7);font-size:13px;text-align: left;
  width:75%;}
.easy_steps h2{text-align: center;font-weight:700;margin-bottom:60px}
.footer_align img{width:200px;}
.normal-menu img{margin-top:85px;width:120px}

@media screen and (min-width:1200px){
  .menu_navbar{display:block}
  .mobile-menu{display:none}
  .mobile-navbar{display:none !important}
  .mobile-user{display:none !important}
}
@media screen and (max-width:1199px){
  .normal-user{display:none !important}
  .mobile-navbar{display:block !important}
  .menu_navbar{display:none}
  .fullbody {
    min-height: auto;
    margin-top: 50px !important;
  }
  .european .content h1 {
    color: #fff;
    font-size: 30px;
    text-align:center}
    .european .content p {
      color: #fff;
      line-height: 27px;
      font-size: 19px;
    }
  .three_simple_steps{margin-top:100px}
  .button_side {
    margin-top: 10px;
  }
  .funds {
    margin-top: 100px;
  }
  .mobile-navbar {padding-left:20px !important;}
  .borderLeft{border:0px solid transparent !important}
  /* .user{padding-right:0px !important;
    display:flex !important;align-items: center !important;
    justify-content: space-between !important;
  } */
  .user{
    position: absolute;
top: 98px;
width: 100%;
left: 0px;
padding-right:0px !important
  }
  .mobile-navbar{
    display: flex !important;
align-items: center;
justify-content: space-between;
position: fixed;width: 100%;
background: #fff;
top:0px;
  }
  ul.social-icons{margin-bottom:0px;} 
  .footer p {
    padding-right: 12px;
    padding-left: 15px;
  }
  .mobile-navbar{  padding-left: 30px;
    padding-top: 16px;}
  .marginTop{margin-top:19px !important}
  .flex-start{display:flex !important;align-items: center !important;justify-content: flex-start !important;}
  .user .mobile-navbar{padding-left:0px;z-index:999} 
  .mobile-menu {
    position: fixed;
    width: 250px;
    max-width: 250px !important;
    height: 100vh;
    background: #fff;
    left: 0px;
    padding:20px
  }
  .button_side .fa-times{float:right}
  .mobile-navbar img.logo{width: 100px;
    height: 45px;
    object-fit: contain;
    margin-left: 0px;
    margin-right: 10px;
    margin-left: auto;}
    .mobile-navbar .fa-bars{padding-right:20px}
  .mobile-navbar .mobile-menu{opacity:0}
  .user{padding-top:0px !important;padding-bottom: 15px;border:0px solid transparent !important}
  .banner{text-align: center !important;}
  .violet-border{border:0px solid transparent !important}
  .marginTop img{margin-bottom: 30px;}
  .funds .image{text-align: center;}
  .footer{padding-left:20px !important;padding-right: 0px !important;}
  .multicryptowallet{padding:0px;}
  .multicryptoleft{padding-left:16px !important;} 
  .footer-visa{margin:auto}
  .social{padding-right:10px !important;padding-left: 20px !important;}
}
@media screen and (max-width:767px){
  .coin{text-align:center !important}
  h2{text-align:center !important}
  p{text-align:center !important}
  .easiest_fastest{text-align:center !important;}
  .funds{margin-bottom: 30px;margin-top:80px}
  .footer-content{
    text-align: center;
margin-bottom: 20px;
  }
  .marginTop{
    margin-top:19px !important
  }
  .more .content {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 20px;
  }
  .more .content h2 {
    font-weight: 700;
    width: 100%;
    line-height: 34px;
    font-size: 20px;
    color: #000;
  }
  .more .content p {
    width: 100%;
  }
  .footer_content{text-align: center;}
  .footer_content img{margin-bottom:30px;}
  .footer_visa .footer-visa{margin:auto !important}
  .requirements{padding:10px 0px !important;}
  h1,h2{font-size:25px !important;}
}
@media screen and (max-width:575px){
  .multicryptowallet{margin-top:30px;} 
  .get_started{padding-bottom:0px !important}
  .three_steps{padding-top:0px !important}
  p{
    width:100% !important;
    padding-right: 0px !important;
  }
  .three_simple_steps {
    max-height: 292px;
    min-height: 292px;
    margin-top: 100px;
  }
  .three_simple_steps img{width:65% }
  .easiest_fastest_way{text-align: center !important;}
  .easiest_fastest_way img {
    width: 100%;
  }
  .instant_delivery img{width:60% !important}
  .thirty_supported{margin-top:0px !important;}
  .main_content{margin-bottom: 30px !important;margin-top:0px !important;
  padding-top: 0px !important;}
  .instant{margin-top:40px !important;}
  .easiest_fastest{margin-top: 0px !important;}
  .easiest_fastest h2{padding-top:0px !important}
  .footer_content.footer_logo{justify-content: center !important;}
  body .seventy{margin-bottom: 30px!important;}
  .footer_visa{padding-bottom: 24px;}
  .footer-store1{padding-top:0px !important}
  .fullbody{
    margin-top: 20px !important;
  }
  .fullbody {
    min-height: auto;
  }
  .card_issued img{width:100% !important}
  .transaction img{width:100%}
  .card_issued{display:block !important;max-height:350px !important;min-height:350px !important;}
  .transaction{display:block !important;max-height:350px !important;min-height:350px !important;}
  .footer_content{text-align: center !important;}
  .footer_visa .footer-visa {
    margin-right: auto !important;
    text-align: center !important;
  }
  .transaction img {
    position: relative !important;
    right: 0px !important;
  }
  .footer_content p{margin-top:30px;
    padding-left:20px;padding-right:20px !important;
  text-align: justify !important ;}
  .footer-store li {
    padding: 5px 5px;
  }
  .multicryptowallet .description{text-align: justify !important;padding-left:20px !important;
  padding-right:20px !important}
  .need_to_know_more p {font-size: 13px;}
  .how_to_guides p {font-size: 13px;}
  .need_to_know_more h2{font-size: 16px;}
  .how_to_guides h2{font-size: 16px;}
  .need_to_know_more .content button{font-size:13px !important}
  .how_to_guides .content button{font-size:13px !important;}
  .footer_content img{margin:auto !important}
  .footer .flex-between{padding-right:0px !important}
  .flex.brands{display:block !important}
  .border p{font-size:14px}
  .brands img {
    width: auto;
    margin-bottom: 30px;
  }
  .accordion {
    width: 100%;
  }
  .european .content h1 {
    color: #fff;
    text-align: center;
  }
  .european{margin-bottom:30px}
  .thirty{padding-left:0px !important}
  .coin{text-align: center;}
  .footer-store li {
    font-size: 11px;
  }
  .swipe{text-align: center;}
  .swipe{font-size:14px;}
  .european .content p {
    color: #fff;
    font-size: 13px;
  }
    .footer-content li:nth-child(n) {
      font-size: 12px;
    }
    .footer-store li{margin-bottom:20px;}
    .requirements{padding-bottom:0px !important}
 
}

.footer_visa{display:flex;align-items: center;justify-content: flex-end;}
.footer_logo{width:150px;}
.footer_content{text-align: center;}
.brand{text-align: center;padding-bottom:20px !important;padding-top:20px !important}
.brand_bg{background: #f5f5f5 !important;width:95% !important;margin:auto !important;}
.coin{margin-bottom: 20px !important;}
.banner img{width:70%;}
.banner {text-align: center !important;}
.more img{
  max-height: 455px;
  min-height: 455px;
  object-fit: cover;
  border-radius: 40px;
}
.accordion-button:not(.collapsed)::after{
  content:"-";
  background-image: none !important;
  position: relative;
left: 5px;
}
.accordion-item:focus-visible{
  box-shadow: 0px 0px !important;
  outline:none !important;
  transform: none !important;
  animation:none !important
}
.accordion-button::after{
  content:"+";
  background-image: none !important;
  position: relative;
left: 14px;
}
.need_to_know_more img{max-height:225px;min-height:225px;}
.how_to_guides img{max-height:225px;min-height:225px;}
.social{padding-top:30px;border-top: 1px solid #eaeaea;}
.footer_visa .footer-visa{margin-right:30px;margin-top:30px;margin-bottom: 30px;}
.border p{font-size:14px !important;margin-bottom: 0px !important;}
.border{display:flex;align-items: center;justify-content: space-between;}
.easy_steps p{margin-top:30px}
.card_issued{    background: #D4DF46;
  border-radius: 20px;
  padding: 20px 50px;
  max-height: 200px;
  min-height: 200px;}
.cards_issued img.wallet{margin-top: 30px;}
.card_issued h2 p{font-size:14px;}
.transaction{background:#88CA7F;border-radius:20px;padding: 30px 50px;max-height:200px;min-height:200px;
margin-top:20px;}
.transaction h2 p{font-size:14px;}
.transaction img{position: relative;
  right: -57px;}
.accordion-body p{font-size:14px !important}
button:focus-visible{outline: none !important;transform: none !important;box-shadow:0px 0px !important}
.brand{padding-right:16px !important}
.funds .description{font-weight: 700;text-align: center;}
.marginTop p{font-size:13px;font-weight:600;color:#000}
.button_side a{color:#989898;font-size:14px;font-weight:700}
a{text-decoration: none;}
.button_side a.active{color:#000}
p{color:rgba(0,0,0,0.8);font-size:14px;line-height:2;margin-bottom:0px}
.footer{padding-top:30px}
.borderLeft{border-left:1px solid #ccc;padding-left:0px !important;}
.footer p{text-align: justify;}
.banner:after{content:"";background:Yellow;filter:blur(4px);width: 600px !important;height:600px !important}
.easiest_fastest_way img{width:auto}
.need_to_know_more p{color:#000;}
.how_to_guides p{color:#000;}
.border p {width:90%}
@media screen and (max-width:480px){
  ul.footer-store{display:block !important;
    padding:0px 30px}
    .footer-store li:nth-child(n){content:none;margin-right:0px}
}
@media screen and (max-width:360px){
 ul.footer-content{display:block !important}
 ul.footer-content li{margin-bottom:30px}

}

.social-icons li{background:#000;width:36px;height:36px;border-radius:50%}
.social-icons i{font-size:20px;color:#fff;position: relative;top: 7px;
  left: 1px;}
a.NFT{font-size:20px;}
.brand_bg{width: 90%;
  margin: auto;
  padding: 20px;}
  .easiest_fastest{display:flex;align-items: center;justify-content: center;}
.euro{margin-top:100px !important;}
.get_it{padding-left:30px !important}
.invite_friends h1{font-weight:700}
.invite_friends p{color:#b0b1b3 !important}
.border{max-height:78px;min-height:78px}
.search  h2{color:#373737;font-size:40px;font-weight:700}
.multicryptowallet{margin-bottom:30px !important}
.home .thirty_supported h2{text-transform: uppercase;}
.thirty_supported{margin-top:40px}
.home .get_started h2{text-transform: uppercase;}
.three_simple_steps .s1{font-weight:500}
.three_simple_steps .description{font-size: 20px !important;
  line-height: 29px;}
.program .marginTop{margin-top: -19px !important;}
.steps h2{color:#000;font-size:20px;margin-top:30px}
.steps p{color:#373737 !important;margin-bottom: 20px;}
.need_to_know_more h2{color:#000 !important;font-weight:700}
.how_to_guides h2{color:#000 !important;font-weight:700}
.footer-content a{color:#000}
.security h2{font-size:30px;font-weight:600}
.accordion-header .accordion-button{box-shadow:0px 0px}
.accordion-body{border-bottom:1px solid #eaeaea}
@media screen and (max-width:1330px){
  .menu_navbar {
    position: fixed;
    width: 184px;
  }
}
.footer_content.footer_logo{text-align:left;display: flex;
  align-items: center;}
.instant_delivery img{width:50% !important}
.easiest_fastest_way img{width:60%;}
.easiest_fastest_way{text-align: left;}
.exchange .easiest_fastest_way{text-align:left}
.card_issued img{width:65%}
@media screen and (max-width:1280px){
  .funds .description {
    font-weight: 700;
    font-size: 14px;
  }
}
/* .avatara img{width:40px;height:40px;border-radius:50%;} */
.send-money .easiest_fastest_way{text-align:center}
.easiest_fastest{margin-top: 10px !important;} 
.normal-user img{width:30px;height:30px;}
.affordable img{width:50%;}
.affordable{text-align:center !important}
.chiptoss-card{text-align: center !important;}
.chiptoss-card img{width:75%}
.social-icons li:hover{
  background:#80C886 !important;
  transition: all 0.5s ease-in-out;
  cursor:pointer
}
.footer-store li:hover{
  background:#80C886 !important;
  transition: all 0.5s ease-in-out;
  cursor:pointer
}
.footer-content a:hover li{
  color:#80C886 !important;
  transition: all 0.5s ease;
}
.linear-gradient img{margin-right: 5px;}
.need_to_know_more .content button:hover{
  background:#000;
  color:#fff;
  transition: all 0.5s ease-in-out;
  border:1px solid #000
}
.how_to_guides .content button:hover{
  background:#000;
  color:#fff;
  transition: all 0.5s ease-in-out;
  border:1px solid #000
}
.avatara img{width:40px;height:40px;border-radius:50%}
.normal-user img{cursor: pointer;}
.normal-user .user{width:20px !important;height:20px !important;}
.program .main_content{margin-bottom:10px !important}